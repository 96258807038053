import React, {useEffect} from 'react'
import styles from '../style';
import { HeroBanner, TechnologyPreview, SuccessStory, Partner, Footer } from '../components';

const Homepage = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <HeroBanner />
        </div>
      </div>

      <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          
          <TechnologyPreview />

          <Partner />

          {/* <SuccessStory /> */}

          <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-blue" />

          <Footer />

          {/* <Stats />
          <Business />

          <Billing />
          <CardDeal />
          <Testimonials />
          <Clients />
          <CTA />
          <Footer /> */}
        </div>
      </div>
    </div>
  )
}

export default Homepage;