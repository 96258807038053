import React from 'react';
import styles from '../style';
import { putthida, wei, juhua, jiayu, zhiqi } from '../assets/founders';

const OurTeam = () => {
  const teamMembers = [
    {
      name: 'Dr. Wei Cheng',
      role: 'Co-Founder',
      image: wei,
      bio: 'Wei is an expert in Smart Transportation systems. He enjoys designing algorithms and protocols; and making them into reality.',
    },
    {
      name: 'Dr. Juhua Hu',
      role: 'Co-Founder',
      image: juhua,
      bio: 'Juhua is an expert in Machine Learning and Computer Vision. She is addicted to turning data into actionable insights for the well-being of people’s life.',
    },
    {
      name: 'Jiayu Li',
      role: 'Co-Founder',
      image: jiayu,
      bio: 'Jiayu\'s expertise is in the areas of computer vision, deep learning, and data science. Her research and expertise lie in applying state-of-the-art technology to address real-world problems.'
    },
    {
      name: 'Putthida Samrith',
      role: 'Co-Founder',
      image: putthida,
      bio: 'Putthida specializes in leveraging technology to drive innovation in urban living. Her expertise lies in the development of vehicle routing algorithms to optimize curbside parking, showcasing her passion for designing user-centric solutions that positively impact urban life.',
    },
    {
      name: 'Dr. Zhi-Qi Cheng',
      role: 'Key Member',
      image: zhiqi,
      bio: 'Zhi-Qi Cheng is an expert in Multimedia and Computer Vision, with a focus on autonomous driving and video surveillance. He is passionate about transforming data into intelligent systems that enhance safety and efficiency in real-world environments.',
    }
  ];

  return (
    <section className={`${styles.flexCenter} flex-col my-4 items-center text-center bg-gray-100 py-12`}>
      <h2 className={`${styles.heading2} text-gradient`}>Meet Our Founding Team</h2>
      <p className={`${styles.paragraph} max-w-[770px] mt-4`}>
        Our team is a dedicated group of professionals passionate about transforming curb management. Get to know the individuals behind Ai4Curb who are driving innovation and making a difference.
      </p>
      <div className={`flex flex-wrap justify-center mt-6 ml-6 mr-6`}>
        {teamMembers.slice(0, 2).map((member, index) => (

          <div key={index} className={`w-full sm:w-1/2 md:w-1/3 p-4 h-full sm:h-[450px]`}>
            <div className={`bg-white rounded-lg overflow-hidden shadow-md p-6 h-full flex flex-col`}>
              {/* Smaller Image */}
              <img src={member.image} alt={`Team Member ${index + 1}`} className="w-40 h-40 mb-4 rounded-full object-cover self-center" />
              <div>
                <h3 className={`text-xl font-semibold mb-2`}>{member.name}</h3>
                <p className={`text-gray-600 text-sm`}>{member.role}</p>
              </div>
              <p className={`text-gray-500 text-sm mt-4`}>{member.bio}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={`flex justify-center`}>
        {teamMembers.slice(2).map((member, index) => (
          <div key={index + 2} className={`w-full sm:w-1/2 md:w-1/3 p-4 h-full sm:h-[450px]`}>
            <div className={`bg-white rounded-lg overflow-hidden shadow-md p-6 h-full flex flex-col`}>
              {/* Smaller Image */}
              <img src={member.image} alt={`Team Member ${index + 3}`} className="w-40 h-40 mb-4 rounded-full object-cover self-center" />
              <div>
                <h3 className={`text-xl font-semibold mb-2`}>{member.name}</h3>
                <p className={`text-gray-600 text-sm`}>{member.role}</p>
              </div>
              <p className={`text-gray-500 text-sm mt-4`}>{member.bio}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurTeam;
