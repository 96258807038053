import { people01, people02, people03, facebook, instagram, linkedin, twitter, send, shield, star  } from "../assets";
import {aiwaysion, bellevue, comotion, lasvegas, nsf, pactrans, nvidia, uw, wsdot, sbir, completestreet} from "../assets/partners";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "about",
    title: "About Us",
  },
  {
    id: "technology",
    title: "Technology",
  },
  {
    id: "solution",
    title: "Solutions",
  },
];

export const technology = [
  {
    id: "tech-1",
    icon: star,
    title: "Curbside Sign Reading",
    content:
      "Automatically interprets curbside signs using computer vision, enhancing navigation by providing real-time information on parking regulations",
  },
  {
    id: "tech-2",
    icon: shield,
    title: "Zone and Sign Localization",
    content:
      "Enables precise vehicle positioning through sensor data, facilitating accurate navigation and supporting applications for autonomous vehicles",
  },
  {
    id: "tech-3",
    icon: send,
    title: "Parked Car Detection",
    content:
      "Utilizes computer vision to identify stationary vehicles, optimizing parking space management and improving traffic flow",
  },
  {
    id: "tech-4",
    icon: star,
    title: "Curbside Navigation Algorithm",
    content:
      "Employs advanced algorithms to optimize navigation routes, specifically designed for curbside areas, enhancing efficiency in pick-up/delivery logistics and reducing congestion",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Bla blab blalksdfsjkdfjsdkfjkdsfjklsdf.... sdfksdfljkdsflkjsdfjlkdsflj kdskfjlsdkjfsdjkfkjdsf....",
    name: "Jane Doe 1",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Bla blab blalksdfsjkdfjsdkfjkdsfjklsdf.... sdfksdfljkdsflkjsdfjlkdsf ljkdskfjlsdkjfsdjkfkjdsf....",
    name: "Jane Doe 2",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "Bla blab blalksdfsjkdfjsdkfjkdsfjklsdf.... sdfksdfljkdsflkjsdfjlkdsfl jkdskfjlsdkjfsdjkfkjdsf....",
    name: "Jane Doe 3",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  // {
  //   id: "social-media-1",
  //   icon: instagram,
  //   link: "https://www.instagram.com/",
  // },
  // {
  //   id: "social-media-2",
  //   icon: facebook,
  //   link: "https://www.facebook.com/",
  // },
  // {
  //   id: "social-media-3",
  //   icon: twitter,
  //   link: "https://www.twitter.com/",
  // },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/jctechs",
  },
];

export const partners = [
  {
    id: "partner-1",
    logo: nsf,
  },
  {
    id: "partner-7",
    logo: uw,
  },
  {
    id: "sbir",
    logo: sbir
  },
  {
    id: "complete-street",
    logo: completestreet
  },
  {
    id: "partner-8",
    logo: pactrans,
  },
  {
    id: "partner-9",
    logo: comotion,
  },
  {
    id: "partner-10",
    logo: aiwaysion,
  },
  {
    id: "partner-11",
    logo: nvidia,
  },
  {
    id: "partner-2",
    logo: wsdot,
  },
  {
    id: "partner-3",
    logo: bellevue,
  },
  {
    id: "partner-4",
    logo: lasvegas,
  }
  // ,  {
  //   id: "partner-5",
  //   logo: seattle,
  // }
  // ,  {
  //   id: "partner-6",
  //   logo: tacoma,
  // },
];

export const solutions = [
  {
    title: "Digitization of Inventory, Regulation, and Asset",
    description: "Digitization of curbside parking inventory and regulation via using AI technologies to automatically read and locate curbside parking signs from the street videos taken by dashcams...",
  },
  {
    title: "Curb Occupancy",
    description: "Curbside parking occupancy tracking via using AI technologies to automatically count the number of curbside parked cars from the street videos taken by dashcams...",
  },
  {
    title: "Implementation, Integration, and Management",
    description: "Integration of old data and collection of new data. Integration of our API/service with existing/future internal/external systems such as curb reservation, payment, and parking enforcement. Cloud-based data warehousing...",
  },
  {
    title: "Driving Directions",
    description: "Mobile App that provides turn-by-turn driving directions to city asset maintenance team for checking problematic curbside assets. Mobile App that provides turn-by-turn driving directions to drivers for finding parking/loading spots...",
  },
];