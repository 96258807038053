import React from 'react'
import { Route, Routes } from 'react-router-dom';
import styles from "./style";
import { Navbar } from './components';

import Homepage from './pages/Homepage';
import About from './pages/About';
import Solutions from './pages/Solutions';
import Technology from './pages/Technology';
import ContactUs from './pages/ContactUs';

const App = () => (
  <div className='bg-primary w-full overflow-hidden'>
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Navbar />
      </div>
    </div>

    <div>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/solution" element={<Solutions />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </div>

    {/* <div className={`bg-primary ${styles.flexStart}`}>
      <div className={`${styles.boxWidth}`}>
        Homepage
      </div>
    </div> */}

    {/* <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        Stats 
        Business 
        Billing 
        CardDeal 
        Testimonials 
        Clients 
        CTA 
        Footer 
      </div>
    </div> */}


  </div>
)

export default App;