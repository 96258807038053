import styles from "../style";
import { tech1, tech2, tech3, tech4 } from "../assets";

export default function TechnologyListing() {

  return (
    <section className={`${styles.flexCenter} flex-col my-4 items-center text-center`}>

      {/** Header and description */}
      <div className="bg-gray-100 dark:bg-gray-100 dark:text-gray-100">
        <div className="container mx-auto space-y-16 px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
          
          {/* Heading */}
          <div className="text-center">
            <h2 className={`${styles.heading2} text-gradient pt-20`}>
              Our Technology
            </h2>
            <h3 className={`mt-4 mx-auto text-xl font-medium leading-relaxed text-gray-700 dark:text-gray-500 lg:w-3/3`}>
              Embrace the future of curb management with Ai4Curb, the driving force behind innovation at JC-TECHS.
              Our technology, born from a commitment to redefine curb management, uses cutting-edge AI to process street videos from off-the-shelf dashcams. 
              Say goodbye to complex and time-consuming on-street surveys as Ai4Curb digitizes parking inventory, regulations, and assets. 
              We also enhance the driving experience for both city workers and end-users by providing intuitive navigation solutions that make curbside parking hassle-free. 
              Explore the simplicity, efficiency, and commitment to a greener future embedded in Ai4Curb on this page.
            </h3>
          </div>
          {/* END Heading */}
          
        </div>
      </div>

      {/** Curbside sign reading */}
      <div className={`flex flex-col items-center md:flex-row mt-4 items-center justify-center`}>
        <div className={`md:w-1/3 p-3`}>
          <img src={tech3} alt="Curbside Sign Reading" className="h-full w-full object-cover object-center"/>
        </div>

        <div className={`md:w-1/3 max-w-[770px] p-6 flex flex-col items-center justify-center text-left`}>
          <h4 className="mb-2 text-lg font-bold sm:text-xl">
          Curbside Sign Reading
          </h4>
          <p className={`${styles.paragraph} mt-5`}>
            Automatically interprets curbside signs using computer vision, enhancing navigation by providing real-time information on parking regulations
          </p>
        </div>
      </div>

      {/** Inventory Management */}
      <div className={`flex flex-col items-center md:flex-row mt-4 items-center justify-center text-right`}>
        <div className={`md:w-1/3 p-3`}>
          <h4 className="mb-2 text-lg font-bold sm:text-xl">
              Zone and Sign Localization
          </h4>
          <p className={`${styles.paragraph} mt-5`}>
            Enables precise vehicle positioning through sensor data, facilitating accurate navigation and supporting applications for autonomous vehicles
          </p>          
        </div>
        <div className={`md:w-1/3 max-w-[770px] p-6 flex flex-col items-center justify-center text-left`}>
          <img src={tech2} alt="Parked Car Detection" className="h-full w-full object-cover object-center"/>
        </div>
      </div>

      {/** Park car detection */}
      <div className={`flex flex-col items-center md:flex-row mt-4 items-center justify-center`}>
        <div className={`md:w-1/3 p-3`}>
          <img src={tech1} alt="Parked Car Detection" className="h-full w-full object-cover object-center"/>
        </div>

        <div className={`md:w-1/3 max-w-[770px] p-6 flex flex-col items-center justify-center text-left`}>
          <h4 className="mb-2 text-lg font-bold sm:text-xl">
            Parked Car Detection
          </h4>
          <p className={`${styles.paragraph} mt-5`}>
            Utilizes computer vision to identify stationary vehicles, optimizing parking space management and improving traffic flow
          </p>
        </div>
      </div>

      {/** Navigation */}
      <div className={`flex flex-col items-center md:flex-row mt-4 items-center justify-center text-right`}>
        <div className={`md:w-1/3 p-3`}>
          <h4 className="mb-2 text-lg font-bold sm:text-xl">
            Curbside Navigation Algorithm
          </h4>
          <p className={`${styles.paragraph} mt-5`}>
            Employs advanced algorithms to optimize navigation routes, specifically designed for curbside areas, enhancing efficiency in pick-up/delivery logistics, finding curbside parking zones that meet driver's needs, curbside parking sign maintenance; and reducing congestion
          </p>          
        </div>
        <div className={`md:w-1/3 max-w-[770px] p-6 flex flex-col items-center justify-center text-left`}>
          <img src={tech4} alt="Navigation app" className="h-full w-full object-cover object-center"/>
        </div>
      </div>

    </section>
  );
}

