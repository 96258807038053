import styles from "../style";
import { bg1, parking1, ourvalues } from "../assets";
import WhyChooseUs from "./WhyChooseUs";
import OurTeam from "./OurTeam";
import OurValues from "./OurValues";
import 'animate.css';

const Mission = () => {
  return (
    <div id="about" >
      <section className={`flex md:flex-row flex-col ${styles.paddingY}`}>
        <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 pt-40`}>

          <div className="flex flex-row justify-between items-center w-full">
            <h1 className="ml-10 text-gradient flex-1 font-poppins font-semibold ss:text-[62px] text-[52px] text-black ss:leading-[100.8px] leading-[75px] animate__animated animate__slideInLeft">
              We are changing the way people do curbside parking
            </h1>
          </div>
        </div>

        <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative pt-20`}>
          <img src={parking1} alt="billing" className="w-[100%] h-[100%] relative z-[5]" />

          {/* gradient start */}
          {/* <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
          <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
          <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" /> */}
          {/* gradient end */}
        </div>
          
      </section>

      {/* Our Mission Section */}
      <section className={`${styles.flexCenter} flex-col my-4 items-center text-center`}>
        
        <h2 className={`${styles.heading2} text-gradient pt-20`}>
          Our Mission
        </h2>

        <div className={`flex flex-col items-center md:flex-row mt-4`}>
          <div className={`md:w-1/2 p-6`}>
            {/* Add your image here */}
            <img src={bg1} alt="background mission" className="w-full h-auto mb-4" />

            {/* Feel free to add more images or customize styling */}
          </div>

          <div className={`md:w-1/2 max-w-[770px] p-6 flex flex-col items-center justify-center`}>

            <p className={`${styles.paragraph} mt-5`}>
              JC-Techs is committed to making curbside parking easier, faster, and smarter for everyone. 
              We provide a suite of AI-powered solutions that help cities, businesses, and individuals 
              manage curbside parking more efficiently and sustainably. 
              We believe that our technology has the potential to revolutionize the way 
              curbside parking is managed around the world.
            </p>
          </div>
        </div>
      </section>

      {/* Our Value Section */}
      <section className={`${styles.flexCenter} flex-col my-4 items-center text-center`}>
        
        <div className={"flex flex-col items-center lg:flex-row p-5"}>
          {/* <img src={ourvalues} alt="Our Values" style={{maxWidth: '50%', height: 'auto', display: 'block', margin: 0}} className="w-full h-auto mb-4 rounded-full" /> */}
          <img src={ourvalues} alt="Our Values" className="flex-1 h-full m-0 w-full mb-2 max-w-[80%] md:max-w-[50%]" />
          <OurValues/>
        </div>

        {/* <img src={ourvalues} alt="Our Values" style={{maxWidth: '50%', height: 'auto', display: 'block', margin: 0}} className="w-full h-auto mb-4 rounded-full" />
        <OurValues /> */}

      </section>


      {/**Why choose us */}
      <section className={`${styles.flexCenter} flex-col my-4 items-center text-center py-2`}>
        <WhyChooseUs />
      </section>

      {/* Our Team Section */}
      <OurTeam />

    </div>
  );
};

export default Mission;