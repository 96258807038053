import 'animate.css';

export default function SolutionHeader() {

  return (
    <>
      {/* Hero Section: Promo Box with Header Actions */}
      <div className="relative bg-white dark:bg-gray-0 dark:text-gray-100">

        {/* Main Header */}
        <header
          id="page-header"
          className="relative flex flex-none items-center py-8"
        >
          {/* END Main Header Content */}
        </header>
        {/* END Main Header */}

        {/* Hero Content */}
        <div className="dark container relative mx-auto px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
          <div className="-mb-8 -mt-16 rounded-3xl bg-gray-200 p-5 dark:bg-gray-700 lg:-mb-16 lg:-mt-24">
            <div
              className="overflow-hidden rounded-xl bg-cover"
              style={{
                backgroundImage:
                  'url("https://cdn.tailkit.com/media/placeholders/photo-wpU4veNGnHg-1280x800.jpg")',
              }}
            >
              <div className="bg-white/95 px-6 py-12 text-center dark:bg-gray-800/95 lg:py-32">
                {/* <div className="mb-2 inline-flex rounded border border-gray-200 bg-gray-100 px-2 py-1 text-sm font-medium leading-4 text-gray-800 dark:border-gray-600/50 dark:bg-gray-700/50 dark:text-gray-200">
                  v6.0 is now live!
                </div> */}

                <h1 className="mb-4 text-5xl pb-5 font-black text-black dark:text-white animate__animated animate__slideInLeft">
                  Next-Gen Curb Management
                </h1>

                <h2 className="mx-auto text-xl font-medium leading-relaxed text-gray-700 dark:text-gray-300 lg:w-2/3 animate__animated animate__slideInLeft">
                  Step into the future of managing curbside parking with our state-of-the-art AI4Curb solutions. Experience innovation as we transform how we regulate parking, manage inventory, and track parking occupancy. 
                  Our approach focuses on simplicity and efficiency by utilizing street videos from common dashcams to streamline the process. Let's explore the potential together with our AI-powered solutions, where the future of efficient curbside experiences unfolds.
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* END Hero Content */}
      </div>
      {/* END Hero Section: Promo Box with Header Actions */}
    </>
  );
}