import React from 'react';
import { FaTrophy, FaBrain, FaCogs, FaMap, FaLock } from 'react-icons/fa';

const OurValues = () => {
  const values = [
    {
      icon: <FaTrophy className="w-8 h-8 text-white" />,
      background: '#FDAE48',
      title: 'Sustainability through Innovation',
      subtitle: 'We are committed to pioneering environmentally conscious solutions through cutting-edge AI technologies. Our focus on digitizing inventory, regulation, and assets aims to reduce the ecological footprint while providing efficient and accurate curb management.',
    },
    {
      icon: <FaBrain className="w-8 h-8 text-white" />,
      background: '#0097B2',
      title: 'User-Centric Efficiency',
      subtitle: 'Our dedication to customers is reflected in our commitment to making curb management effortless and user-friendly. We prioritize simplicity, real-time updates, and accuracy to ensure our solutions enhance efficiency and meet the unique needs of our clients.',
    },
    {
      icon: <FaCogs className="w-8 h-8 text-white" />,
      background: '#8C52FF',
      title: 'Respecting Your Privacy',
      subtitle: 'Upholding the highest standards of privacy is our priority. Our seamless data integration ensures the confidentiality and security of both old and new information. Our cloud-based solutions prioritize the protection of sensitive data, fostering trust in our implementation, integration, and management processes.',
    },
    {
      icon: <FaMap className="w-8 h-8 text-white" />,
      background: '#00BF63',
      title: 'Green Solutions for Urban Living',
      subtitle: 'Our commitment to green living extends beyond navigation. Through innovative AI technologies, we provide comprehensive solutions for curbside parking management. By reducing greenhouse gas emissions, easing traffic congestion, and preventing illegal parkings, we actively contribute to a more sustainable urban environment.',
    },
    {
      icon: <FaLock className="w-8 h-8 text-white" />,
      background: '#FF5757',
      title: 'Service with Integrity',
      subtitle: 'At JC-TECHS, integrity is at the heart of everything we do. We bring the best practices to our customers, drive innovations for better service, and seamlessly integrate into diverse ecosystems. Our goal is to deliver top-quality products and services while minimizing the costs and efforts of our valued customers.'
    }
  ];

  return (

    <>
    {/* Timeline: Contextual */}
    <div className="relative py-5 dark:text-gray-100">
      
      {/* Vertical Guide */}
      <div
        className="absolute bottom-0 left-0 top-0 flex w-10 flex-col justify-center md:w-12"
        aria-hidden="true"
      >
        <div className="mx-auto h-2.5 w-1 grow-0 rounded-t bg-gradient-to-b from-transparent to-gray-100 dark:to-gray-800" />
        <div className="mx-auto w-1 grow bg-gray-100 dark:bg-gray-800" />
        <div className="mx-auto h-2.5 w-1 grow-0 rounded-b bg-gradient-to-t from-transparent to-gray-100 dark:to-gray-800" />
      </div>
      {/* END Vertical Guide */}

      {/* Timeline */}
      <ul className="relative space-y-4 pl-10 md:pl-12">

        {/* Event */}
        {values.map((value, index) => (
          // Each card main
    
          <li key={index} className="relative">
            {/* Inside each card */}
            {/* <div className="flex items-center justify-center pt-5">
              {value.icon}
            </div> */}
            <div className="absolute bottom-0 left-0 top-0 mt-5 flex w-10 -translate-x-full justify-center md:w-12">
              <div style={{ background: value.background, borderColor: value.background }} 
                className={`h-3 w-3 rounded-full ring ring-white ring-opacity-100 ring-offset-2 dark:bg-white-300 dark:ring-white-900 dark:ring-offset-gray-900`} />
            </div>

            <div style={{ background: value.background }}
              className="rounded-xl bg-rose-50 p-4 text-rose-900 hover:ring hover:ring-black-100 hover:ring-offset-2 dark:bg-black-900/50 dark:text-rose-100 dark:ring-offset-gray-900 dark:hover:ring-black-800">
              
              <h4 className="mb-2 font-bold text-white">{value.title}</h4>
              <p className="text-sm leading-relaxed text-white">{value.subtitle}</p>
            </div>
          </li>
        ))}
        {/* END Event */}
      </ul>
      {/* END Timeline */}
    </div>
    {/* END Timeline: Contextual */}
    </>
  );
};

export default OurValues;
