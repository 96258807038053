import styles from "../style";
import { techBanner } from "../assets";

const TechnologyHeader = () => {

  return (

    <section className={`${styles.flexCenter} flex-col my-4 items-center text-center`}>

      {/** banner image */}
      <img src={techBanner} alt="ai4curb" style={{maxWidth: '100%', height: 'auto', display: 'block', margin: 0, marginTop: -20}} className="w-full h-auto mb-4" />

      {/* <h2 className={`${styles.heading2} text-gradient`}>Our Technology</h2>

      <div>
        Step into the future of curbside management with our cutting-edge AI4Curb solutions. 
        Embrace innovation as we redefine curbside parking regulation, inventory management, and parking occupancy tracking. 
        No more hassle with traditional challenges of on-street walking-based surveys or complicated mapping – we've got you covered. 
        Our solutions are all about simplicity and efficiency by using street videos from everyday dashcams to get the job done. 
        Join us in reimagining curbside management; and explore the possibilities with our AI-driven solutions, where the future of curbside efficiency begins.
      </div> */}
      
      {/* Our Technology Section */}
      {/* <div>

        <div className={`items-center md:flex-row mt-4`}>

          {solutions.map((solution, index) => (
            <div key={index} className={`flex flex-col items-center md:flex-row mt-4 bg-gray-100 rounded-lg shadow-md overflow-hidden`}>
              
              <div className={`md:w-1/3 p-6`}>
                <img src={trans} alt={solution.title} className="w-full h-auto mb-4"  />
              </div>

              <div className={`md:w-1/2 max-w-[770px] p-6 text-left`}>
                <h3 className={`text-xl font-semibold mb-2 text-gradient`}>{solution.title}</h3>
                <ul className={`text-gray-600 text-sm list-disc pl-6`}>
                  {solution.points.map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </div>

            </div>
          ))}

        </div>
      </div> */}

    </section>
  );
};

export default TechnologyHeader;


// TODO:
// Key value: Safety (afffected by looking for parking), Effortless value: Up-to-date & accurate curb managmeent (inventory asset / occupancy)
// Tech: Sign reading (Inventory & Regulation DB creation) & zone localization, Street Asset Detection, Occupancy Tracking (Parked Car detection), API for data integration & dissemination, Routing A (city maintainent team & drivers nav)
// Take out Seattle city, and Open Mobility, Tacoma,
// 