import { technology } from "../../constants";
import styles, { layout } from "../../style";
import CustomButton from "../CustomButton";
import { Link } from 'react-router-dom';

const FeatureCard = ({ icon, title, content, index }) => (

  <div className={`flex flex-row p-6 rounded-[20px] ${index !== technology.length - 1 ? "mb-6" : "mb-0"} feature-card`} >
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-button`}>
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-black text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimBlack text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const TechnologyPreview = () =>  (
  <section id="technology" className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
        AI-Powered Solutions For <br className="sm:block hidden" />
        <span className="text-gradient">Curbside Parking Management</span>{" "}
      </h2>
      
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Ai4Curb's technology can be used to solve a wide range of curbside parking problems, such as:
      </p>

      <div className={`${styles.paragraph} max-w-[470px] mt-5`}>
        <ul className="list-disc ml-5">
          <li>Reducing the time people spend searching for parking</li>
          <li>Reducing traffic congestion</li>
          <li>Increasing parking revenue for cities</li>
          <li>Reducing emissions</li>
          <li>Improving accessibility for people with disabilities</li>
          <li>Supporting new transportation modes, such as shared mobility and curbside pickup and delivery</li>
        </ul>
      </div>

      <Link to='/technology'>
        <CustomButton text="Learn More About Our Technology" styles={`mt-10`} />
      </Link>
    </div>

    <div className={`${layout.sectionImg} flex-col`}>
      {technology.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </div>
  </section>
);

export default TechnologyPreview;