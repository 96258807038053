import styles from "../../style";
import CustomButton from "../CustomButton";
import 'animate.css';
import { Link } from 'react-router-dom';

const HeroBanner = () => {
  return (
    <section id="home" className={`${styles.paddingY} ${styles.flexCenter} flex-col my-4 items-center text-center`}>
      <div className={`flex-1 ${styles.flexCenter} flex-col xl:px-0 sm:px-16 px-6 pt-40`}>

        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[62px] text-[52px] text-black ss:leading-[100.8px] leading-[75px] animate__animated animate__slideInLeft">
            Solving <br className="sm:block hidden" />{" "}
            <span className="text-gradient">Curbside Parking Problems</span>{" "}
            <h1 className="font-poppins font-semibold ss:text-[58px] text-[52px] text-black ss:leading-[100.8px] leading-[75px] w-full animate__animated animate__slideInLeft">
              One City At A Time
            </h1>
          </h1>

        </div>

        <p className={`${styles.paragraph} max-w-[970px] mt-5 animate__animated animate__fadeIn`}>
          JC-Techs is committed to making curbside parking easier, faster, and smarter for everyone. 
          We provide a suite of AI-powered solutions that help cities, businesses, and individuals 
          manage curbside parking more efficiently and sustainably.
        </p>

        <div className="pt-5 pb-5">
            <Link to='/about'>
            <CustomButton text={"Learn More"} />
          </Link>
        </div>
      </div>

      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        {/* Gradient start */}
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
        {/* Gradient end */}
      </div>
    </section>
  );
};

export default HeroBanner;
