import React, { useRef, useEffect } from 'react';
import styles from '../style';

const ContactForm = () => {

  const formRef = useRef();

  useEffect(() => {
    const handleUnload = () => {
      // Reset the form fields when the user navigates away
      formRef.current.reset();
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <>
    {/* Contact Section: Split */}
    <div className="relative flex items-center overflow-hidden bg-gray-50">
      <div className="absolute bottom-0 right-0 top-0 w-full bg-white lg:w-1/2" />
      <div className="container relative mx-auto px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-0">
          {/* Heading */}
          <div className="flex items-center space-y-6 lg:px-14 xl:px-28">
            <div className="w-full">
              <div className="mb-1 text-sm font-bold uppercase tracking-wider text-primary-600">
                We reply in 24hrs
              </div>
              <h2 className={`${styles.heading2} mb-4 text-4xl font-black`}>
                Get in touch
              </h2>
              <h3 className="text-xl font-medium leading-relaxed text-gray-700">
                Please feel free to use the form to contact us. We will get back to you as soon as possible.
              </h3>
            </div>
          </div>
          {/* END Heading */}

          {/* Contact Form */}
          <div className="flex flex-col items-center lg:px-14 xl:px-20">
            <form className="w-full space-y-6"
              action="https://formspree.io/f/xayggrwy"
              method="POST"
              ref={formRef}
            >
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div className="space-y-1">
                  <label htmlFor="firstname" className="font-medium">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-50 focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-100 dark:placeholder-gray-400 dark:focus:border-primary-500"
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="lastname" className="font-medium">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-100 dark:placeholder-gray-400 dark:focus:border-primary-500"
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label htmlFor="email" className="font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-100 dark:placeholder-gray-400 dark:focus:border-primary-500"
                />
              </div>
              <div className="space-y-1">
                <label htmlFor="message" className="font-medium">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={6}
                  className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-100 dark:placeholder-gray-400 dark:focus:border-primary-500"
                  defaultValue={""}
                />
              </div>
              <button
                type="submit"
                className="inline-flex w-full items-center justify-center space-x-2 rounded-lg border border-blue-500 bg-button px-5 py-3 font-semibold leading-6 text-white hover:border-blue-400 hover:bg-blue-400 hover:text-white focus:ring focus:ring-blue-300 focus:ring-opacity-50 active:border-blue-500 active:bg-blue-500 dark:focus:ring-blue-300 dark:focus:ring-opacity-90 lg:w-auto"
                >
                <svg
                  className="hi-mini hi-paper-airplane inline-block h-5 w-5 opacity-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z" />
                </svg>
                <span>Send Message</span>
              </button>

            </form>
          </div>
          {/* END Contact Form */}
        </div>
      </div>
    </div>
    {/* END Contact Section: Split */}
  </>
  );
};

export default ContactForm;
