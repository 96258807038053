import React, { useEffect, useState } from 'react';
import { FaTrophy, FaBrain, FaCogs, FaMap, FaHandsHelping, FaLock } from 'react-icons/fa';
import styles from "../style";

const WhyChooseUs = () => {
  const benefits = [
    {
      icon: <FaTrophy className="w-8 h-8 text-yellow-500" />,
      title: 'Proven Track Record',
      subtitle: 'We have successfully implemented projects for cities by delivering tangible results and significant improvements in parking availability, traffic flow, and environmental sustainability.',
    },
    {
      icon: <FaBrain className="w-8 h-8 text-blue-500" />,
      title: 'AI-powered Innovation',
      subtitle: 'We leverage the power of AI to automate data collection and analysis, providing real-time insights and predictive capabilities to optimize curb usage and inform strategic decision-making.',
    },
    {
      icon: <FaCogs className="w-8 h-8 text-purple-500" />,
      title: 'Comprehensive Solutions',
      subtitle: 'We offer a comprehensive suite of services, from digitizing curb inventory and regulations to providing real-time parking information and turn-by-turn driving directions.',
    },
    {
      icon: <FaMap className="w-8 h-8 text-green-500" />,
      title: 'Seamless Integration',
      subtitle: 'Our solutions seamlessly integrate with existing parking and transportation systems, ensuring a smooth transition and maximizing efficiency.',
    },
    {
      icon: <FaLock className="w-8 h-8 text-purple-500" />,
      title: 'Privacy-First Solutions',
      subtitle: 'Our privacy commitment is reflected in our camera-based solutions. Backed by cybersecurity expertise, we adhere to industrial standards and prioritize privacy to ensure a robust and secure environment.'
    },
    {
      icon: <FaHandsHelping className="w-8 h-8 text-pink-500" />,
      title: 'Committed Partner',
      subtitle: 'We are committed to partnering with cities to achieve their unique goals and build smart, sustainable communities for the future.',
    }
  ];

  const [visibleItems, setVisibleItems] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleItems((prev) => (prev < benefits.length - 1 ? prev + 1 : prev));
    }, 500); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, [benefits.length]);

  return (
    <div className="why-choose-us-container py-16">
      <div className="max-w-6xl mx-auto">
        <h2 className={`${styles.heading2} text-gradient pt-20`}>
          Why Choose Us?
        </h2>  

        <div className={`flex flex-wrap justify-center mt-6`}>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto">
          {benefits.map((benefit, index) => (
            // Each card main
            <div
              key={index}
              className={`bg-white rounded-lg overflow-hidden shadow-lg border border-gray-200 transform transition-transform duration-300 mx-auto ${
                index <= visibleItems ? 'scale-100 hover:scale-110' : 'scale-90'
              }`}
            >
              {/* Inside each card */}
              <div className="flex items-center justify-center p-6">
                {benefit.icon}
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-base text-gray-700">{benefit.subtitle}</p>
              </div>
              
            </div>
          ))}
        </div>

        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
