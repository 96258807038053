import { partners } from "../../constants";
import styles from "../../style";

const Partner = () => (
  <section className={`${styles.flexCenter} flex-col my-4 items-center text-center`}>
    
    <h2 className={`${styles.heading2} text-gradient`}>
      Our Partners & Sponsors
    </h2>

    <div className={`${styles.flexCenter} flex-wrap w-full mt-4`}>
      {partners.map((client) => (
        <div key={client.id} className={`flex-1 ${styles.flexCenter} sm:min-w-[192px] min-w-[120px] m-5`}>
          <img src={client.logo} alt="client_logo" className="sm:w-[192px] w-[100px] object-contain" />
        </div>
      ))}
    </div>
  </section>
);

export default Partner;