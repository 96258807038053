import { solution1, solution2, solution3, solution4 } from "../assets";
import styles from "../style";

const Solution = () => {
  const solutions = [
    {
      title: "Digitization of Inventory, Regulation, and Asset",
      points: [
        "Digitization of curbside parking inventory and regulation via using AI technologies to automatically read and locate curbside parking signs from the street videos taken by dashcams",
        "Efficiently and accurately create and maintain curbside parking inventory and regulation database for sector or citywide digital curb",
        "Efficiently and accurately create and maintain curbside asset database for traffic signs, regulatory signs, and directional signs with automatic problematic asset detection",
        "Cloud-based software and API for curbside parking inventory management, asset management, and parking enforcement",
        "Comply with Curb Data Specification (CDS)"
      ],
      image: solution1, // Replace with the actual image URL
    },
    {
      title: "Curb Occupancy",
      points: [
        "Curbside parking occupancy tracking via using AI technologies to automatically count the number of curbside parked cars from the street videos taken by dashcams",
        "Cloud-based software for historical and/or real-time occupancy monitoring and performance reporting",
        "Curb availability predictive modeling",
        "APIs for accessing historical, real-time, and predicted curb availability",
      ],
      image: solution2, // Replace with the actual image URL
    },
    {
      title: "Implementation, Integration, and Management",
      points: [
        "Integration of old data and collection of new data",
        "Integration of our API/service with existing/future internal/external systems such as curb reservation, payment, and parking enforcement",
        "Cloud-based data warehousing",
      ],
      image: solution3, // Replace with the actual image URL
    },
    {
      title: "Driving Directions",
      points: [
        "Mobile App that provides turn-by-turn driving directions to city asset maintenance team for checking problematic curbside assets",
        "Mobile App that provides turn-by-turn driving directions to drivers for finding parking/loading spots, which meet their needs, with occupancy analytical/real-time results and avoiding illegal parking",
      ],
      image: solution4, // Replace with the actual image URL
    },
  ];

  return (

    <section className={`${styles.flexCenter} flex-col my-4 items-center text-center ${styles.paddingY}`}>
      
      {/* Our Solution Section */}
    <>
      {/* <div className="bg-white dark:bg-gray-900 dark:text-gray-100"> */}
      <div>
        <div className="container mx-auto space-y-16 px-4 py-16 lg:px-8 lg:py-5 xl:max-w-7xl">
          {/* Heading */}
          <div className="text-center">
            <h2 className={`${styles.heading2} text-gradient`}>
              Overview of Our Solutions
            </h2>
          </div>
          {/* END Heading */}

          {/* Features */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-12">

            {solutions.map((solution, index) => (

              // <div key={index} className="py-5 sm:flex sm:space-x-10">
              <div key={index} className="rounded-lg border border-white bg-white p-5 shadow-sm transition hover:border-primary-600 dark:border-gray-300 dark:shadow-none dark:hover:border-primary-400 md:p-7 xl:p-10">

              {/* <div key={index} className={`flex flex-col items-center md:flex-row mt-4 bg-gray-100 rounded-lg shadow-md overflow-hidden`}> */}
                
                {/* <div className={`md:w-1/3 p-6`}>
                  <img src={trans} alt={solution.title} className="w-full h-auto mb-4"  />
                </div>

                <div className={`md:w-1/2 max-w-[770px] p-6 text-left`}>
                  <h3 className={`text-xl font-semibold mb-2 text-gradient`}>{solution.title}</h3>
                  <ul className={`text-gray-600 text-sm list-disc pl-6`}>
                    {solution.points.map((point, idx) => (
                      <li key={idx}>{point}</li>
                    ))}
                  </ul>
                </div> */}

                <div className="group relative mb-8 ml-3 inline-flex h-12 w-12 flex-none items-center justify-center sm:mb-0 sm:mt-5">
                  {/* <div className="absolute inset-0 -m-3 -rotate-6 rounded-xl bg-primary-400 transition duration-150 ease-out group-hover:rotate-6 group-hover:scale-110 dark:bg-primary-900" /> */}
                  <img src={solution.image} alt={solution.title} className="w-full h-auto mb-0"  />
                </div>

              <div>
                <h4 className={`mb-4 text-lg font-bold mt-5`}>{solution.title}</h4>

                <ul className={`${styles.paragraph} leading-[25px] text-left text-gray-600 text-sm list-disc pl-6`}>
                {/* <ul className="leading-relaxed text-gray-600 dark:text-gray-400"> */}
                    {solution.points.map((point, idx) => (
                      <li key={idx}>{point}</li>
                    ))}
                </ul>

              </div>

              </div>
            ))}
          </div>
          {/* END Features */}

          
        </div>
      </div>
      {/* END Features Section: Modern with Icons Left */}
    </>


    </section>
  );
};

export default Solution;