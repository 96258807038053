import React, {useEffect} from 'react'
import styles from '../style';
import { SolutionHeader, Solution, Footer } from '../components';

const Solutions = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

      <SolutionHeader />
      
      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Solution />
        </div>
      </div>

      <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
     
    </div>
  )
}

export default Solutions;